import { Grid } from '@material-ui/core';
import React from 'react';
import { useGtXs } from '../../hooks/useGtXs';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './ActivistTheaterClubView.module.scss';

const Title = () => {

    const gtXs = useGtXs()
    const titleVariant = gtXs ? "ColisseumRegular58" : "ColisseumRegular48"

    return (
        <div className={styles.Title}>
            <Typography variant={titleVariant} paragraph>
                Clube de Teatro Ativista
            </Typography>
        </div>
    )
}

const Dates = () => {
    return (
        <div className={styles.Dates}>
            <Typography variant="RocGroteskRegular20" paragraph>
                28 <b>NOVEMBRO</b> 2024
            </Typography>
            <Typography variant="RocGroteskRegular20" paragraph>
                03 <b>JULHO</b> 2025
            </Typography>
        </div>
    )
}

const Description = () => {

    const gtXs = useGtXs()
    const variant = gtXs ? "RocGroteskMedium20" : "RocGroteskMedium16"

    return (
        <div className={styles.Description}>

            <Paragraph variant={variant} style={{ marginBottom: 20 }}>
                O lançamento do Clube de Teatro Ativista marca o início das comemorações dos 30 anos da <b>Associação Amigos do Coliseu do Porto, cuja origem</b> é um exemplo vivo de ativismo popular espontâneo na defesa deste Teatro. E se foi na rua que se gritou <b>"O Coliseu é nosso!", é para a rua que o Coliseu se abre agora, devolvendo esse ativismo que é pedra basilar da sua História.</b>
            </Paragraph>
        </div>
    )
}

const Description2 = () => {

    const gtXs = useGtXs()
    const variant = gtXs ? "RocGroteskMedium20" : "RocGroteskMedium16"

    return (
        <div className={styles.Description}>

            <Paragraph variant={variant} style={{ marginBottom: 20 }}>
                O Clube de Teatro Ativista tem direção artística e orientação do ator, encenador e professor portuense Pedro Lamares. O objetivo é criar um espaço livre para o pensamento e o debate, deixando a arte conduzir a ação e o pensamento conduzir a arte. Neste Clube, todos vão dar voz e corpo a um manifesto que se pretende disruptivo, com manifestações artísticas que terão lugar no Coliseu e na cidade.
            </Paragraph>
        </div>
    )
}


const TargetAudience = () => {
    return (
        <div className={styles.TargetAudience}>
            <Typography variant="ColisseumRegular30" component="h3">
                Público-Alvo
            </Typography>
            <Paragraph variant="RocGroteskRegular16">
                Participantes com ou sem experiência artística, maiores de 15 anos de idade
            </Paragraph>
        </div>
    )
}


const AdditionalInfo = () => {

    return (
        <div className={styles.AdditionalInfo}>
            <Typography variant="ColisseumRegular30" component="h3">
                Informações Adicionais
            </Typography>

            <div className={styles.AdditionalInfoContent}>
                <Paragraph variant="RocGroteskRegular16">
                    Uma aula por semana
                </Paragraph>
                <Paragraph variant="RocGroteskRegular16">
                    <b>Quintas feiras | 18h30 - 21h00</b>
                </Paragraph>
            </div>
        </div>
    )
}

const Banner = () => {
    return (
        <div className={styles.Teacher}>
            <div className={styles.Photo} />
        </div>
    )
}

export default function ActivistTheaterClubView() {

    const gtXs = useGtXs()

    return (
        <div>
            <div className={styles.SectionBanner} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>

                    {gtXs ?

                        <Grid container spacing={10}>

                            <Grid item xs={5}>
                                <Dates />
                                <TargetAudience />
                                <AdditionalInfo />
                            </Grid>

                            <Grid item xs={7}>
                                <Title />
                                <Description />
                                <Banner />
                                <Description2 />
                            </Grid>

                        </Grid>

                        :

                        <Grid container justify="space-between" className={styles.Presentation}>
                            <Grid item xs={12}>
                                <Title />
                                <Dates />
                                <Description />
                                <TargetAudience />
                                <AdditionalInfo />
                                <Banner />
                                <Description2 />
                            </Grid>
                        </Grid>
                    }
                </main>

            </Grid>
        </div>
    )
}
